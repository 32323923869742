import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = ({ data }) => (
  <Layout activeLink='home'>
    <SEO title="Home"/>
    <h2>Home</h2>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
  </Layout>
);

export const query = graphql`
    query {
        allSanityBlogPost {
            totalCount
            nodes {
                name
            }
        }
    }
`;

export default IndexPage;
